var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "text-center mb-3" },
                [
                  _c("v-avatar", { attrs: { size: "150" } }, [
                    _c("img", {
                      attrs: { alt: "user", src: "/images/Icon.png" },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "mx-auto text-center",
                  attrs: { "max-width": "500" },
                },
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      { staticClass: "text-center mx-auto" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", attrs: { color: "black" } },
                          [_vm._v("mdi-alert")]
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("view.PageErrorNotFound.title"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      _vm._s(_vm.$t("view.PageErrorNotFound.description"))
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", large: "", color: "primary" },
                          on: { click: _vm.clearUserDetail },
                        },
                        [_vm._v(_vm._s(_vm.$t("action.return")))]
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }